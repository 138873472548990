import React, { useState, useEffect } from 'react';

import FileManager, { Permissions } from 'devextreme-react/file-manager';
import RemoteFileSystemProvider from 'devextreme/file_management/remote_provider';
import { Popup } from 'devextreme-react/popup';
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';
import { fileItems } from './data.js';
import { useSelector, useDispatch } from 'react-redux'
import { createRecord, deleteRecord, fileUpload, getAllRecords, getFile } from '../../redux/actions/Data.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import {API_URL} from '../../commonData.js'

const allowedFileExtensions = [];
const loadPanelPosition = { of: '#file-manager' };


function App () {
  const dispatch = useDispatch()

  const [state, setState] = useState({
    popupVisible: false,
    imageItemToDisplay: {},
  })
  
   const [files, setFiles] = useState([])
   const [current, setCurrent] = useState(null)

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     currentPath: 'Widescreen',
  //     popupVisible: false,
  //     imageItemToDisplay: {},
  //   };

  //   this.displayImagePopup = this.displayImagePopup.bind(this);
  //   this.hideImagePopup = this.hideImagePopup.bind(this);
  //   this.onCurrentDirectoryChanged = this.onCurrentDirectoryChanged.bind(this);
    
  //   this.
  // }
 async function getItems(parentDirectory) {
    console.log(parentDirectory)
    let { dataItem} = parentDirectory 
    let items = await dispatch(getAllRecords( dataItem ? dataItem._id : null));
    
      console.log(items)
    return items
  }
  
  
  function createDirectory(parentDirectory, name) {
    console.log(parentDirectory)
    let { dataItem } = parentDirectory  
      dispatch(createRecord({name, isDirectory: true}, dataItem ? dataItem._id : null))
    }
  
  

  function displayImagePopup(e) {
    setState({
      ...state,
      popupVisible: true,
      imageItemToDisplay: {
        name: e.file.name,
        url: e.file.dataItem.fileUrl,
      },
    });
  }

  function hideImagePopup() {
      setState({
      ...state,
      popupVisible: false,
    });
  }

  function onCurrentDirectoryChanged(e) {
  console.log(e.component.option())
  console.log(e.component.option('currentPath'))
  setCurrent(e.component.option('currentPath'))
    console.log(state.currentPath)
    // setState({...state, currentPath: e.component.option('currentPath')})
  
  }
  
  function handleUpload (e) {
    console.log(e)
    let parent = e.destinationDirectory.dataItem ? e.destinationDirectory.dataItem._id : null
    
    const formData = new FormData();
		formData.append('file', e.fileData);
		
    dispatch(fileUpload(formData, parent))
      .then(() => {
        getItems(e.destinationDirectory)
    })
    .catch(err => {
      console.log(err)
    })
    
  }


  
  
  function handleFiles () {
    dispatch(getAllRecords())
    .then(a => {
      console.log(a)
      setFiles(a)
    })
    .catch(err => {
      console.log(err)
    })
  }
  
  function downloadItems(items) {
  console.log(items)
  console.log(API_URL)
    for(let item of items){
      let { _id, fileUrl } = item && item.dataItem ?  item.dataItem : {};
      console.log(item.dataItem)
          //  dispatch(getFile(_id))
        window.open(API_URL + `/files/download/${_id}`, '_blank')
    }
  // azure.downloadFile(items[0].path);
}


function deleteItem(item) {
  console.log(item)
  dispatch(deleteRecord(item.key))
}

  
  useEffect(() => {
      handleFiles()
  }, [])
  
  
  
    
  const fileSystemProvider = new CustomFileSystemProvider({
    getItems,
    createDirectory,
    renameItem,
    deleteItem,
    copyItem,
    moveItem,
    uploadFileChunk,
    downloadItems,
  });
  
  console.log(files)

  console.log(current)
  console.log(!current ? false : true)
    return (
      <div style={{width: '100%'}}>
        <LoadPanel visible={state.loadPanelVisible} position={loadPanelPosition} />

        <FileManager
          // currentPath={state.currentPath}
          id="file-manager" 
          
          fileSystemProvider={fileSystemProvider} allowedFileExtensions={allowedFileExtensions}
          onSelectedFileOpened={displayImagePopup}
          onCurrentDirectoryChanged={onCurrentDirectoryChanged}
          onFileUploading={handleUpload}
          height='450'
          >
          <Permissions
            create={true}
            copy={true}
            move={true}
            delete={true}
            rename={true}
            upload={!current ? false : true}
            download={true}>
          </Permissions>
        </FileManager>

        <Popup
          maxHeight={600}
          hideOnOutsideClick={true}
          title={state.imageItemToDisplay.name}
          visible={state.popupVisible}
          onHiding={hideImagePopup}
          className="photo-popup-content">

          <img width="100%" height="100%" src={state.imageItemToDisplay.url} className="photo-popup-image" />
        </Popup>
    <small style={{textAlign: 'right'}} onClick={() => { localStorage.clear(); window.location.href = '/signin'}}>Logout</small>
        
      </div>
    );
}






function renameItem(item, name) {
    console.log(item)
    console.log(name)
}


function copyItem(item, destinationDirectory) {
  console.log(item)
  console.log(destinationDirectory)
}

function moveItem(item, destinationDirectory) {
  console.log(item)
  console.log(destinationDirectory)
}

function uploadFileChunk(fileData, uploadInfo, destinationDirectory) {
  
  console.log(fileData)
  console.log(uploadInfo)
  console.log(destinationDirectory)

  // let promise = null;

  // if (uploadInfo.chunkIndex === 0) {
  //   const filePath = destinationDirectory.path ? `${destinationDirectory.path}/${fileData.name}` : fileData.name;
  //   promise = gateway.getUploadAccessUrl(filePath).then((accessUrls) => {
  //     uploadInfo.customData.accessUrl = accessUrls.url1;
  //   });
  // } else {
  //   promise = Promise.resolve();
  // }

  // promise = promise.then(() => gateway.putBlock(
  //   uploadInfo.customData.accessUrl,
  //   uploadInfo.chunkIndex,
  //   uploadInfo.chunkBlob,
  // ));

  // if (uploadInfo.chunkIndex === uploadInfo.chunkCount - 1) {
  //   promise = promise.then(() => gateway.putBlockList(
  //     uploadInfo.customData.accessUrl,
  //     uploadInfo.chunkCount,
  //   ));
  // }

  // return promise;
}



export default App;
