import './App.css';
import { Routes, Route, Navigate } from "react-router-dom";

//Components
import FileManager from './pages/FileManager/FileManager';
import Login from './pages/Login';

import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react';
import { getAllRecords } from './redux/actions/Data';


const token = localStorage.idToken;


const ProtectedRoute = ({ isAuthUser, children }) => {
  if (!token && !isAuthUser) {
    return <Navigate to="/signin" replace />;
  }

  return children;
};


const PublicRoute = ({ isAuthUser, children }) => {

  if (isAuthUser) {
    return <Navigate to="/" replace />;
  }

  return children;
};




const App = () => {
  const { isAuthUser} = useSelector(({auth}) => auth)
  const dispatch = useDispatch();

  useEffect(() => {
    if(token) {
      dispatch(getAllRecords())
    }
  
  }, [token])



  return (
    <div className="App">
       <Routes>
              <Route path="/" element={<ProtectedRoute isAuthUser={isAuthUser}>
                    <FileManager/>              
                </ProtectedRoute>}  />
              <Route path="/signin" element={<PublicRoute isAuthUser={isAuthUser}>
                    <Login/>              
                </PublicRoute>} />
             </Routes>
    </div>
  );
}

export default App;
