// import {fetchError, fetchStart, fetchSuccess} from "../actions";

import {RESIZE, SET_ACTIVE_NODE, SET_FILES, SET_MESSAGE, SET_STUDENTS, SET_USERS} from "./types";
import axios from 'axios';

import { authHeader } from '../auth-header';

const API_URL = 'https://files.bugtech.online/api/v1'



export const getAllRecords = (params) => async dispatch => {
    console.log(params)
  return await axios
  .get(API_URL + `/files/getAll/${params}`, { headers: authHeader() })
  .then((response) => {
    dispatch({type: SET_FILES, payload: response.data})
    return response.data;
  });
};


export const fileUpload = (data, parent) => async dispatch => {
  return await axios
  .post(API_URL + `/files/upload/${parent}`, data, { headers: authHeader() })
  .then((response) => {
    dispatch(getAllRecords());
    return response.data;
  });
};



export const createRecord = (data, parent) => async dispatch => {
  return await axios
  .post(API_URL + `/files/create/${parent}`, data, { headers: authHeader() })
  .then((response) => {
    dispatch(getAllRecords());
    return response.data;
  });
};


export const updateRecord = (data) => async dispatch => {
  return await axios
  .put(API_URL + `/files/updateById/${data._id}`, data)
  .then((response) => {
    dispatch(getAllRecords());
    return response.data;
  });
};




export const deleteRecord = (data) => async dispatch => {
  return await axios
  .delete(API_URL + `/files/deleteById/${data}`)
  .then((response) => {
    dispatch(getAllRecords());
    return response.data;
  });
};



export const getAllUsers = () => async dispatch => {
  return await axios
  .get(API_URL + "/users/getAll")
  .then((response) => {
    dispatch({type: SET_USERS, payload: response.data})
    return response.data;
  });
};



export const getFile = (id) => async dispatch => {
    console.log(id)
  return await axios
  .get(API_URL + `/files/download/${id}`, {  })
  .then((response) => {
  console.log(response)
    // dispatch({type: SET_FILES, payload: response.data})
    return response.data;
  }).catch(err => {
    console.log(err)
  });
};
