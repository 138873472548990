import './styles.css';

import React from 'react';
import App from './App';
import themes from 'devextreme/ui/themes';
import ReactDOM from "react-dom/client";
// import "./index.css";
import { BrowserRouter } from "react-router-dom";
//Redux
import { Provider } from 'react-redux';
import configureStore, { history } from './redux/store';


const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById("app"));



themes.initialized(() => 
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <BrowserRouter
         history={history}
    >
      <App />
    </BrowserRouter>
    </Provider>
   </React.StrictMode>
)); 


